// 设置api
let apipost = '/admin/v1/external/post/factory_service/'
let apiget = '/admin/v1/external/get/factory_service/'

export default {
  // 工厂
  FactoryList: `${apiget}ClientFactoryList`,
  FactoryCreate: `${apipost}ClientFactoryCreate`,
  FactoryUpdate: `${apipost}ClientFactoryUpdate`,
  FactoryDelete: `${apipost}ClientFactoryDelete`,
  FactorySecret: `${apiget}ClientFactorySecret`,
  // 工厂关联产品
  FactoryProModelList: `${apiget}ClientFactoryProductModelList`,
  FactoryProModelCreate: `${apipost}ClientFactoryProductModelCreate`,
  FactoryProModelUpdate: `${apipost}ClientFactoryProductModelUpdate`,
  FactoryProModelDelete: `${apipost}ClientFactoryProductModelDelete`,
  // 出厂申请
  ProApplyList: `${apiget}ClientProductApplyList`,
  ProApplyNum: `${apiget}ClientProductApplyNum`,
  ProApplyCreate: `${apipost}ClientProductApplyCreate`,
  ProApplyUpdate: `${apipost}ClientProductApplyUpdate`,
  ProApplyCheck: `${apipost}ClientProductApplyCheck`,
  ProApplyRetry: `${apipost}ClientProductApplyRetry`,
  ProApplyStop: `${apipost}ClientProductApplyStop`,
  ProApplyRestart: `${apipost}ClientProductApplyRestart`,
  ProApplyInfo: `${apiget}ClientProductApplyByTokenGetInfo`,
  ProApplyMaterial: `${apiget}ClientProductApplyByTokenGetMaterial`,
  ProApplyPreSign: `${apiget}GetMacUploadPreSign`,
  // 出厂设备
  UuidDeviceList: `${apiget}ByUuidGetClientDeviceList`,
  YearDeviceList: `${apiget}ByYearGetClientDeviceList`,
  TokenDeviceList: `${apiget}ByTokenGetClientDeviceList`,
  OldDeviceList: `${apiget}OldClientDeviceList`,
}