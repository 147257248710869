<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.name" @change="Search" placeholder="工厂名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.sn_code" @change="Search" placeholder="工厂SN码"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddFactory">新建</el-button>
      </div>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="工厂名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="工厂sn号标识">
        <template v-slot="scope">
          <span>{{ scope.row.sn_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支持的生产方式">
        <template v-slot="scope">
          <span>{{ scope.row.way==1?'有MAC设备在线出厂':scope.row.way==2?'无MAC设备在线出厂':scope.row.way==3?'线下生产':'未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="340px">
        <template v-slot="scope">
         <el-button type="info" size="mini" @click="Info(scope.row)">code/密钥</el-button>
         <el-button type="primary" size="mini" @click="Edit(scope.row)">修改</el-button>
         <el-button type="danger" size="mini" @click="Delete(scope.row)">删除</el-button>
         <router-link style="margin-left: 10px" :to="'/product/factorymodel/'+scope.row.name+'&'+scope.row.code">
           <el-button type="info" size="mini">关联型号</el-button>
         </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.rows"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog :title="edit+'工厂'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="工厂名称" prop="name" label-width="130px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工厂sn_code" prop="sn_code" label-width="130px">
          <el-input v-model="form.sn_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="支持的生产方式" prop="way" label-width="130px">
          <el-select v-model="form.way">
            <el-option label="有MAC设备在线出厂" :value="1"></el-option>
            <el-option label="无MAC设备在线出厂" :value="2"></el-option>
            <el-option label="线下生产" :value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-if="edit=='修改'" type="primary" @click="Validate(Editconfirm)">修改</el-button>
          <el-button v-else  type="primary" @click="Validate(Addconfirm)">创建</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="工厂code&密钥" v-model="infoFormVisible" width="600px">
      <el-form :model="infoform" ref="form">
        <el-form-item label="工厂code" label-width="130px">
          <el-input disabled v-model="infoform.code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密钥" label-width="130px">
          <el-input disabled v-model="infoform.secret" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="infoFormVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../axios/productionManage'
import util from '../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      search: {
        page: 1,
        rows: 10,
        name: '',
        sn_code: '',
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        company_code: 1,
        name: '',
        sn_code: '',
        way: '',
      },
      rules: {
        company_code: [{ required: true, message: '请输入所属公司名称', trigger: 'blur' }],
        name: [{ required: true, message: '请输入工厂名称', trigger: 'blur' }],
        sn_code: [{ required: true, message: '请输入工厂sn_code', trigger: 'blur' }],
        way: [{ required: true, message: '请选择支持的生产方式', trigger: 'blur' }],
      },
      edit: '',
      infoFormVisible: false,
      infoform: {
        code: '',
        secret: '',
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    Info (item) {
      this.infoFormVisible = true
      this.infoform.code = item.code
      api.FactorySecret({
        data: {
          code: item.code
        },
        payload_version: 'v1.0',
        region: 'gb'
      }).then(res => {
        if (res.data.code == 200) {
          this.infoform.secret = res.data.data.secret
          return
        }
        this.$message.error('获取密钥失败' + res.data.msg)
      })
    },
    Search () {
      this.list = []
      api.FactoryList({
        data: this.search,
        payload_version: 'v1.0',
        region: 'gb'
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.list
          this.total = res.data.data.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    AddFactory() {
      this.form = {}
      this.edit = '创建'
      this.dialogFormVisible = true
    },
    Edit (item) {
      this.edit = '修改'
      this.dialogFormVisible = true
      this.form = JSON.parse(JSON.stringify(item))
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Editconfirm () {
      api.FactoryUpdate({
        data: this.form,
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.$message.success('修改成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error('修改失败' + res.data.msg)
      })
    },
    Addconfirm() {
      api.FactoryCreate({
        data: this.form,
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.$message.success('创建成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error('创建失败' + res.data.msg)
      })
    },
    Delete (item) {
      this.$confirm('是否删除该工厂?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      api.FactoryDelete({
        data: {code: item.code},
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    ChangeTime (item) {
      return util.dateFormat(item)
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
