// 生产管理api
import { fetch } from './fetch'
import api from './productionManageurl'

export default {
  // 工厂
  FactoryList: function (params) {
    return fetch({
      url: api.FactoryList,
      method: 'get',
      params: params
    })
  },
  FactoryCreate: function (data,params) {
    return fetch({
      url: api.FactoryCreate,
      method: 'post',
      data: data,
      params: params
    })
  },
  FactoryUpdate: function (data,params) {
    return fetch({
      url: api.FactoryUpdate,
      method: 'post',
      data: data,
      params: params
    })
  },
  FactoryDelete: function (data,params) {
    return fetch({
      url: api.FactoryDelete,
      method: 'post',
      data: data,
      params: params
    })
  },
  FactorySecret: function (params) {
    return fetch({
      url: api.FactorySecret,
      method: 'get',
      params: params
    })
  },
  // 工厂关联产品型号
  FactoryProModelList: function (params) {
    return fetch({
      url: api.FactoryProModelList,
      method: 'get',
      params: params
    })
  },
  FactoryProModelCreate: function (data,params) {
    return fetch({
      url: api.FactoryProModelCreate,
      method: 'post',
      data: data,
      params: params
    })
  },
  FactoryProModelUpdate: function (data,params) {
    return fetch({
      url: api.FactoryProModelUpdate,
      method: 'post',
      data: data,
      params: params
    })
  },
  FactoryProModelDelete: function (data,params) {
    return fetch({
      url: api.FactoryProModelDelete,
      method: 'post',
      data: data,
      params: params
    })
  },
  // 出厂申请
  ProApplyList: function (params) {
    return fetch({
      url: api.ProApplyList,
      method: 'get',
      params: params
    })
  },
  ProApplyNum: function (params) {
    return fetch({
      url: api.ProApplyNum,
      method: 'get',
      params: params
    })
  },
  ProApplyCreate: function (data,params) {
    return fetch({
      url: api.ProApplyCreate,
      method: 'post',
      data: data,
      params: params
    })
  },
  ProApplyUpdate: function (data,params) {
    return fetch({
      url: api.ProApplyUpdate,
      method: 'post',
      data: data,
      params: params
    })
  },
  ProApplyCheck: function (data,params) {
    return fetch({
      url: api.ProApplyCheck,
      method: 'post',
      data: data,
      params: params
    })
  },
  ProApplyRetry: function (data,params) {
    return fetch({
      url: api.ProApplyRetry,
      method: 'post',
      data: data,
      params: params
    })
  },
  ProApplyStop: function (data,params) {
    return fetch({
      url: api.ProApplyStop,
      method: 'post',
      data: data,
      params: params
    })
  },
  ProApplyRestart: function (data,params) {
    return fetch({
      url: api.ProApplyRestart,
      method: 'post',
      data: data,
      params: params
    })
  },
  ProApplyInfo: function (params) {
    return fetch({
      url: api.ProApplyInfo,
      method: 'get',
      params: params
    })
  },
  ProApplyMaterial: function (params) {
    return fetch({
      url: api.ProApplyMaterial,
      method: 'get',
      params: params
    })
  },
  ProApplyPreSign: function (params) {
    return fetch({
      url: api.ProApplyPreSign,
      method: 'get',
      params: params
    })
  },
  // 出厂设备
  UuidDeviceList: function (params) {
    return fetch({
      url: api.UuidDeviceList,
      method: 'get',
      params: params
    })
  },
  YearDeviceList: function (params) {
    return fetch({
      url: api.YearDeviceList,
      method: 'get',
      params: params
    })
  },
  TokenDeviceList: function (params) {
    return fetch({
      url: api.TokenDeviceList,
      method: 'get',
      params: params
    })
  },
  OldDeviceList: function (params) {
    return fetch({
      url: api.OldDeviceList,
      method: 'get',
      params: params
    })
  },
}